.Container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: linear-gradient(to bottom right, #d8fbe8, white);
}

.LinkSection {
  height: 60%;
  width: 100%;
  background: linear-gradient(to right, #40517d, #38ab7c);
}

.SectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

/* Logo Styling */
.IconContainer_logo {
  display: flex;
  align-items: center;
  padding-top: 1%;
  padding-left: 1%;
}

.IconContainer_logo h2 {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
}

.IconContainer_logo .Icon {
  width: 5%;
}

/* Store Logo Styling */
.IconContainer {
  display: flex;
  align-items: center;
}

.IconContainer .Icon_Store {
  width: 20%;
}

.IconContainer h2 {
  color: #fff;
  font-size: 1.5rem;
  font-family: "Times New Roman";
  font-weight: 500;
}

.LinkWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3% 0;
}

.LinkContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 4%;
  width: 100%;
}

.LinkWrapper h3 {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-family: "Roboto";
  font-weight: 500;
}

.PlayStoreSection,
.AppStoreSection {
  width: 18%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ed4123;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(10px);
}

.PlayStoreSection:hover,
.AppStoreSection:hover {
  background: #ed4123;
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.PlayStoreSection .IconContainer,
.AppStoreSection .IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  gap: 2rem;
}

.AboutSection {
  height: 35%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 95%;
  align-items: center;
  overflow: auto;
  padding: 1rem;
}

.AboutSection h1 {
  color: #26405e;
  font-weight: 600;
  font-family: "Roboto";
  text-align: center;
  margin: 0.8rem 0;
  font-size: 1.7rem;
}

.AboutSection p {
  color: #26405e;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.9rem;
  line-height: 1.5;
}

.FooterSection {
  height: fit-content;
  background-color: #000;
  width: 100%;
.container_footer{
 padding:0rem 1.5rem;
  }
}
.Support_Mail {
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.8rem;
  color: #fff;
}

/* Responsive Design */
/* Tablet View (max-width: 1024px) */
@media (max-width: 1024px) {
  .Container {
    padding: 2%;
  }

  .LinkSection {
    height: auto;
    padding: 2% 5%;
  }

  .IconContainer_logo h2 {
    font-size: 1.8rem;
  }
 
  .PlayStoreSection,
  .AppStoreSection {
    width: 30%; 
  }

}

/* Mobile View (max-width: 768px) */
@media (max-width: 768px) {
  .Container {
    height:100vh;
  }

  .IconContainer_logo {
    justify-content: flex-start;
    text-align: center;
    gap:0.5rem;
  }

  .IconContainer_logo h2 {
    font-size: 2rem;
  }
  .IconContainer_logo .Icon {
    width: 12%;
  }
  .Icon_Store {
    width: 5%;
  }
  .LinkWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3% 0;
    margin-top:20%;
  }
  .LinkWrapper h3 {
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    font-family: "Roboto";
    font-weight: 500;
  }
  .LinkContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin:10% 0;
   padding:2% 0;
  
/* Store Logo Styling */
.IconContainer {
  display: flex;
  align-items: center;
  gap:1rem;

}

.IconContainer .Icon_Store {
  width: 25%;
}

.IconContainer h2 {
  color: #fff;
  font-size: 1rem;
  font-family: "Times New Roman";
  font-weight: 500;
}
.PlayStoreSection,
.AppStoreSection {
  width:11rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ed4123;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  padding:0.5rem 1.5rem;
}
}
  .AboutSection h1 {
    font-size: 0.9rem;
  }
  .FooterSection {
 
  }
  .Support_Mail {
    margin-left: 10;
  }
}

/* Small Mobile View (max-width: 480px) */
@media (max-width: 480px) {
  .LinkSection {
    height:50%;
  }
  .AboutSection {
    height: 50%;
  }
  .IconContainer_logo h2 {
    font-size: 1.5rem;
  }

  .PlayStoreSection,
  .AppStoreSection {
    width: 50%;
  }

  .AboutSection h1 {
    font-size: 1.2rem;
  }

  .AboutSection p {
    font-size: 0.8rem;
  }

  .FooterSection {
    font-size: 0.7rem;
    margin:0;
    padding:0;
  }
}





