.MainConatiner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  //   align-items: center;
  .Main_Header {
    height: 7%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-width: 0px 0px 4px 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.06);
    background-color: #fff;

    .Import_Icon_Container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      img {
        width: 1rem;
      }
      h2 {
        font-size: 0.9rem;
        font-weight: 500;
        color: #000056;
        padding-left: 0.5rem;
      }
    }
    .ContinueContainer {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      margin-right: 0.5rem;
      gap: 0.5rem;
      .ContainerButton {
        display: flex;
        padding: 0.6rem 1rem;
        align-items: center;
        background-color: #38ab7c;
        gap: 0.5rem;
        cursor: pointer;
        p {
          color: #fff;
          font-size: 0.9rem;
        }
      }
    }
  }
  .SteppBarContainer {
    height: 7%;
    width: 80%;
    margin-top: 1rem;
    display: flex;
    // flex-direction: column;
    // align-items: center;
    margin: 1rem auto 0rem auto;
  }
  .CSV_Container {
    height: 85%;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .Import_heading_and_Download {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      h1 {
        font-size: 1rem;
        font-weight: 500;
      }
      .DownloadFile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.5rem 0;
        img {
          width: 1rem;
        }
        p {
          padding: 0rem 0.3rem;
          font-size: 0.7rem;
          color: #38ab7c;
        }
      }
    }
    .ImportFile_container {
      border: 0.1rem dotted #bfbfbf;
      width: 100%;
      height: 35%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      img {
        width: 2rem;
      }
      h3 {
        color: #111429;
        font-size: 0.9rem;
      }
      button {
        padding-left: 0.5rem;
        color: #1d92f1;
        cursor: pointer;
        font-size: 0.9rem;
        outline: none;
      }
    }
    .ConditionContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 0.8rem;
        color: #111429;
      }
      .Icon_FileFormat {
        display: flex;
        align-items: center;
        padding: 0.2rem;
        img {
          width: 0.9rem;
        }
        p {
          text-align: center;
          padding: 0.2rem;
        }
      }
    }
  }

  .SelectedFile_container {
    // border: 1px solid red;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    h6 {
      color: #323338;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      font-family: "roboto";
    }
    .FileType_container {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;
      img {
        background-color: #f5f6f8;
        padding: 0.4rem;
        border-radius: 0.5rem;
        cursor: pointer;
      }
      .CSV_Type {
        align-items: center;
        display: flex;
        gap: 0.3rem;
        p {
          color: #fff;
          background-color: #38ab7c;
          padding: 0.4rem 0.5rem;
          font-size: 0.8rem;
          border-radius: 0.5rem;
          cursor: pointer;
        }
        span {
          color: #323338;
          font-size: 0.9rem;
          font-family: "roboto";
        }
      }
    }
  }
  .Fields_Mapping_Container {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    h1 {
      color: #323338;
      font-size: 0.9rem;
      font-weight: 500;
    }
    .Add_Custom_Field_Button {
      display: flex;
      align-items: center;
      background-color: #38ab7c;
      padding: 0.5rem;
      border-radius: 0.5rem;
      gap: 0.5rem;
      cursor: pointer;
      p {
        color: #ffffff;
        font-size: 0.9rem;
        font-family: "roboto";
      }
    }
  }
  .Mapping_data_Heading {
    background-color: #f1f1f5;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    h2 {
      width: 50%;
      color: #111429;
      font-size: 1rem;
      font-weight: 500;
      font-family: "roboto";
    }
  }
  .Mapping_Datas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0.8rem 0;
    h2 {
      color: #111429;
      font-size: 1rem;
      font-weight: 500;
      font-family: "roboto";
      width: 50%;
      padding: 0.5rem 1rem;
    }
    .InputEmail {
      height: 2rem;
      width: 50%;
    }
  }
  .inValid_Data {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
    .Mapping_Datas {
      h2 {
        width: 50%;
        font-size: 1.1rem;
        font-weight: 500;
        color: #000;
        font-family: "roboto";
      }
      p {
        width: 50%;
        font-size: 1rem;
        font-weight: 500;
        color: #323338;
        padding: 0.2rem 1rem;
        font-family: "roboto";
        .letsReview {
          border: 1px solid #38ab7c;
          border-radius: 0.5rem;
          padding: 0.13rem 0.4rem;
          font-size: 0.7rem;
          color: #38ab7c;
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }
    }
    .Mapping_Datas_Header {
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 0.5rem;
      h2 {
        width: 50%;
        font-size: 1.1rem;
        font-weight: 500;
        color: #000;
        font-family: "roboto";
        padding: 0rem 0.9rem;
      }
    }
    h1 {
      width: 50%;
      font-size: 1.3rem;
      font-weight: 500;
      color: #000;
      font-family: "roboto";
      padding: 0.5rem 0.8rem;
    }
  }

  .Duplicate_Data_Container {
    width: 100%;
    margin-top: 1rem;
    p {
      color: #616b7a;
      font-size: 0.9rem;
      font-weight: 400;
      padding: 0.5rem 0;
      font-family: "roboto";
      .Errors {
        color: #f95050;
        font-weight: 400;
        font-size: 0.9rem;
        padding: 0.5rem 0.3rem;
        font-family: "roboto";
      }
    }
    td {
      color: #616b7a;
      font-size: 0.9rem;
      font-weight: 400;
      font-family: "roboto";
    }
    .duplicate_email {
      color: #f95050;
      font-size: 0.9rem;
      font-weight: 400;
      font-family: "roboto";
    }
  }
}
.LoadingContainer {
  height: 10%;
  width: 50%;
  display: flex;
  margin: 27% auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  h2 {
    font-size: 1.3rem;
    font-family: "roboto";
    font-weight: 500;
  }
}
