.notification-sidebar {
  width: 0;
  height: 90vh;
  background-color: #f5f4f4;
  overflow-x: hidden;

  position: fixed;
  top: 7.3% !important;
  right: 0;
  transition: 0.8s ease;
  padding: 20px;
  z-index: 1111;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);

  &.open {
    width: 20%; // Adjust the width for the open state
  }

  /* Add media queries for responsiveness */

  .NotificationsHead {
    color: #26405e;
    font-family: "Roboto";
    font-size: 1rem;
  }

  .main-notification {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .noti-content,
  .isRead-noti-content {
    border: 2px solid rgb(236, 236, 236);
    // background-color: #ffffff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    font-size: 0.7rem;
    font-family: "roboto" !important;
    border-radius: 5%;
    padding: 2%;
    width: 100%;
  }

  .noti-content:hover {
    background-color: #f3f9fe !important;
  }

  .isRead-noti-content:hover {
    background-color: #f3f9fe !important;
  }

  .noti-content {
    color: #616b7a;
    background-color: #e0ebe6 !important;
  }

  .isRead-noti-content {
    color: #a2a5a9;
    background-color: #ffffff !important;
  }

  .noti_inner {
    display: flex;
    align-items: center;
    align-items: start;
    padding: 2%;
    width: 100%;
  }

  .view-button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: #0020fb;
    margin-right: 2px;
  }

  .timestamp {
    font-size: 0.5rem;
    color: #616b7a !important;
    font-family: "roboto" !important;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2%;
  }

  .footer_notify {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .isReadNotify {
    margin: -28% !important;
    padding: 0 !important;
    position: relative;
    bottom: 3rem;
    left: 18.6rem;
    color: #4cab7b;
    font-size: 5.2rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .NotificationsHead {
    color: #26405e;
    font-family: "Roboto";
    font-size: 1rem;
  }
  .MarkAsRead {
    color: #26405e;
    font-family: "Roboto";
    font-size: 0.6rem;
    text-decoration: underline;
    cursor: pointer;
  }
  .MarkAsRead:hover {
    color: #3772b6;
  }

  .main-notification {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .noti-content,
  .isRead-noti-content {
    border: 2px solid rgb(236, 236, 236);
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    font-size: 0.7rem;
    font-family: "roboto" !important;
    border-radius: 5%;
    padding: 2%;
    width: 100%;
  }
}
.Empty_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  width: 100%;
  height: calc(100vh - 160px);
}
// @media (min-width: 1400px) {
//   .notification-sidebar {
//     width: 0;
//     height: 90vh;
//     background-color: #f5f4f4;
//     overflow-x: hidden;
//     position: fixed;
//     top: 5.9%;
//     right: 0;
//     transition: 0.8s ease;
//     padding: 20px;
//     z-index: 1111;
//     box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);

//     &.open {
//       width: 20%;
//     }
//   }
// }
// @media (min-width: 1502px) {
//   .notification-sidebar {
//     width: 0;
//     height: 90vh;
//     background-color: #f5f4f4;
//     overflow-x: hidden;
//     position: fixed;
//     top: 5.9%;
//     right: 0;
//     transition: 0.8s ease;
//     padding: 20px;
//     z-index: 1111;
//     box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
//     &.open {
//       width: 20%;
//     }
//   }
// }
