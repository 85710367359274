.HeadingAndBack_Add {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem;
  h1 {
    padding-left: 0.2rem;
    color: #26405e;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.AddProjectMainContainer {
  width: 50%;
  height: calc(100vh - 160px);
  margin: 1rem auto;
  background-color: #fff;
  overflow-y: auto;
  .TitlesAndInput {
    padding: 1rem;
    .InputsUserId {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0.5rem;
    }
    p {
      color: #26405e;
      font-size: 0.9rem;
      font-family: "Roboto";
    }
    span {
      color: #26405e;
      font-size: 0.8rem;
      font-family: "Roboto";
      padding: 1rem 0rem;
    }
    input {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 48%;
      border-radius: 0.5rem;
    }
    .TextArea {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0.5rem;
      min-height: 10rem;
    }
    .TextArea {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0.5rem;
      min-height: 10rem;
    }
    .TextArea {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0.5rem;
      min-height: 10rem;
    }
  }
  .TitlesAndInputTwoColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.8rem 1rem;
    .CategoriesANdSubCat {
      width: 48%;
      input {
        outline: none;
        border: 1px solid grey;
        font-size: 0.8rem;
        padding: 0.5rem;
        width: 100%;
        border-radius: 0.5rem;
      }
      p {
        color: #26405e;
        font-size: 1.2rem;
        font-family: "Roboto";
      }
    }
  }
  .DocumentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    h3 {
      color: #000;
      font-size: 1.2rem;
      font-family: "Roboto";
      font-weight: 500;
    }
    .AddProjectBTN {
      background-color: #42426e;
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  .MessageFromAdmin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem auto;
    p {
      color: #616b7a;
      font-size: 1rem;
    }
    span {
      color: #616b7a;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      text-align: center;
    }
  }
  .CheckForRegBusiness {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    span {
      color: #26405e;
      font-size: 0.8rem;
      font-family: "Roboto";
      padding: 1rem 0rem;
    }
  }

  .SaveAndCancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    .Cancel {
      width: 48%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      color: #42426e;
      padding: 0.4rem 0.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    .Save {
      width: 48%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #42426e;
      color: #fff;
      padding: 0.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  .disabled {
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;

    background-color: #ccc;
    cursor: not-allowed;
  }

  // .date_picker {
  //   border: 2px solid red;
  // }
  .react-date-picker__button__clear {
    display: none;
    border: 2px solid red;
  }
  .date_picker1 {
    border: 2px solid rgb(0, 255, 106);
  }

  .Common_details {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 0.5rem;
    .Inputs {
      width: 48%;
      color: #000;
      font-weight: 500;
      font-size: 0.8rem;
      //   height: 2.5rem;
    }

    input {
      outline: none;
      border: 1px solid grey;
      font-size: 0.8rem;
      padding: 0.5rem;
      width: 48%;
      border-radius: 0.5rem;
    }

    .TextArea {
      width: 100%;
    }
  }
  .FavUserDialog {
    height: 100%;
    width: 50%;
    border: 1px solid red;
  }
  .StateAndCityNAme {
    color: #26405e;
    font-size: 1.5rem;
    font-family: "Roboto";
    padding: 0.2rem 1rem;
    cursor: pointer;
  }
}
.selectedCity {
  color: blue;
  /* Add any other styles you want for the selected city */
}
.city-checkbox {
  margin: 10px; // Adjust the margin as needed
}
.city-checkbox2 {
  margin-left: 20px;
}
.ImageDialogContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.2rem;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  .CommonIdContainer {
    border: 1px solid rgb(202, 172, 172);
    border-radius: 0.5rem;
    width: 30%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
  }
  .UploadButtonContainer {
    border: 1px solid grey;
    border-radius: 0.2rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    input[type="file"] {
      margin: 0 auto;
    }
    input {
      width: 100%;
    }
    input[type="file"]::file-selector-button {
      display: none;
    }
  }
  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    img {
      height: 90%;
      width: 5rem;
    }
  }
}
.Cancel_Save_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 95%;
  margin: 0 auto;
  .CancelBTN {
    background-color: black;
    border: 1px solid rgb(224, 204, 204);
    padding: 0.2rem 1rem;
    color: #fff;
    cursor: pointer;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-family: "Roboto";
  }
  .SaveBTN {
    background-color: rgb(224, 204, 204);
    border: 1px solid black;
    padding: 0.2rem 1rem;
    color: black;
    cursor: pointer;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-family: "Roboto";
  }
}
.BackBTN_Docs {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 8px;
  width: fit-content;
  margin: 0.8rem 0rem 0rem 1.5rem;
  text-align: center;
}
